import { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { autop } from '@wordpress/autop';
import { useMediaQuery } from 'react-responsive';

import { WEBSITE_URL } from '@/app-configuration';
import { getLanguageId } from '@helpers/language';
import { translatePrice, completeSellPrice } from '@helpers/product';
import { BackgroundHeroCheckup, StudioCheckup } from '@helpers/dataExists';
import PageContext from '@contexts/PageContext';
import { Button, Hero, Modal } from '@components';
import { checkEnabled, checkValue } from '@components/OptionalFeature';

import { smBreakpoint, mdBreakpoint, lgBreakpoint, xlBreakpoint } from '@styles/styles.module.scss';
import styles from './HeroSection.module.scss';

const ProductHeroSection = ({ data, productType, DarkMode, componentIsAvailable }) => {
  const { locale } = useRouter();
  const pageData = useContext(PageContext);
  const [showTermConditionModal, setShowTermConditionModal] = useState(false);
  const [showWarrantyModal, setShowWarrantyModal] = useState(false);
  const [pageType, setPageType] = useState('');
  const [heroSeasonalityType, setHeroSeasonalityType] = useState('N');
  const [backgrondHeroFetchedUrl, setBackgroundHeroFetchedUrl] = useState('');
  const [studioFetchedUrl, setStudioFetchedUrl] = useState('');

  const isMobile = useMediaQuery({ maxWidth: parseInt(smBreakpoint) - 1 });
  const isTablet = useMediaQuery({
    minWidth: parseInt(smBreakpoint) + 1,
    maxWidth: parseInt(lgBreakpoint) - 1
  });
  const isDesktop = useMediaQuery({ minWidth: lgBreakpoint });

  const applications = pageData.ProductTags?.filter((item) => item.Tag === 'Application');

  const promotion = pageData.ProductTags?.filter((item) => item.Tag === 'Promotions')?.shift();

  // check MediaSeasonality
  const heroSeasonality = data?.DisplayOptions?.find((x) => x.DisplayOptionKey == 'MediaSeasonality')?.OptionInformation;

  // check Hero Breadcrumb Enables
  const breadCrumbEnabled = checkEnabled(data?.DisplayOptions, 'DisplayBreadCrumbs');

  // check SpecialBlurb
  const specialBlurbIsAvailable = checkEnabled(data?.DisplayOptions, 'SpecialsBlurb');

  // SpecialBlurbText
  const specialBlurbText = checkValue(data?.DisplayOptions, 'SpecialsBlurb', locale);

  // check PromotionBadge availability
  const promotionEnabled = checkEnabled(data?.DisplayOptions, 'DisplayPromotionBadge');

  // EngDesc for showing on Hero
  const engDesc =
    locale == 'en'
      ? pageData.ProductTags?.find((item) => item.Tag == 'Promotions')?.Descriptions.find((x) => x.Code == promotion.Value).EngDesc
      : pageData.ProductTags?.find((item) => item.Tag == 'Promotions')?.Descriptions.find((x) => x.Code == promotion.Value).FrDesc;

  // check Sell Price is enable
  const sellPriceEnable = checkEnabled(data?.DisplayOptions, 'SellPrice');

  // sellPriceText to show on Hero
  const sellPriceText = checkValue(data?.DisplayOptions, 'SellPriceText', locale);

  // check Monthly Text available
  const monthlyTextEnable = checkEnabled(data?.DisplayOptions, 'MonthlyText');

  // monthlyText
  const monthlyText = checkValue(data?.DisplayOptions, 'MonthlyText', locale);

  // check Rate text is enable
  const rateTextEnable = checkEnabled(data?.DisplayOptions, 'RateText');

  // Rate text
  const rateText = checkValue(data?.DisplayOptions, 'RateText', locale);

  // term text
  const termText = checkValue(data?.DisplayOptions, 'TermText', locale);

  // show Warranty
  const warrantyIsAvailable = checkEnabled(data?.DisplayOptions, 'Warranty');

  // WarrantText
  const warrantyText = checkValue(data?.DisplayOptions, 'WarrantyText', locale);

  // Term Condition modal
  const OpenTermConditionModal = () => {
    setShowTermConditionModal(true);
  };

  const OpenWarrantyModal = () => {
    setShowWarrantyModal(true);
  };

  // check Term Condition is available
  const checkTermCondition = checkEnabled(data?.DisplayOptions, 'DisplayTermsConditions');

  // Term Condition text
  const termsConditionsText = checkValue(data?.DisplayOptions, 'TermsConditionsText', locale);

  // Term Condition main text
  const termsConditionsMainText = checkValue(data?.DisplayOptions, 'TermsConditionsMainText', locale);

  // Term Condition Specific term
  const termsConditionsSpecificText = checkValue(data?.DisplayOptions, 'TermsConditionsSpecificText', locale);

  // check Term Condition is available
  const checkWarranty = checkEnabled(data?.DisplayOptions, 'WarrantyInfo');

  // check if Studio is enable on Hero
  const studioAvailable = checkEnabled(data?.DisplayOptions, 'Studio');

  // Hero image alt
  const heroImgAlt = checkValue(data?.DisplayOptions, 'ImageAccessibilityInfo', locale);

  // check BackgroundHero is Enabled
  const backgroundHeroEnabled = checkEnabled(data?.DisplayOptions, 'BackgroundHero');

  // check Hero video background
  const heroVideoUrl = data?.DisplayOptions?.find((x) => x.DisplayOptionKey == 'BackgroundHeroVideo')?.OptionInformation;
  const heroVideoMobileUrl = data?.DisplayOptions?.find((x) => x.DisplayOptionKey == 'BackgroundHeroVideoMobile')?.OptionInformation;

  const heroVideoEnabled = checkEnabled(data?.DisplayOptions, 'BackgroundHeroVideo');
  const heroVideoMobileEnabled = checkEnabled(data?.DisplayOptions, 'BackgroundHeroVideoMobile') && heroVideoMobileUrl != "";

  // check ComponentTall
  const ComponentTall = checkEnabled(data?.DisplayOptions, 'ComponentTall');

  // Large description
  const LargeDescription = checkEnabled(data?.DisplayOptions, 'LargeDescription');

  // SmallDescription
  const SmallDescription = checkEnabled(data?.DisplayOptions, 'SmallDescription');

  // check Build Your Own button
  const BuildYourOwnEnabled = checkEnabled(data?.DisplayOptions, 'BuildYourOwnButtonTarget');
  const BuildYourOwnText = checkValue(data?.DisplayOptions, 'BuildYourOwnButtonText', locale);
  const BuildYourOwnButtonUrl = checkValue(data?.DisplayOptions, 'BuildYourOwnButtonUrl', locale);
  const BuildYourOwnUrl = BuildYourOwnButtonUrl || `/buildandquote/${data.ProductId}`;

  //check Request A Quote button
  const RequestAQuoteEnabled = checkEnabled(data?.DisplayOptions, 'RequestAQuoteButtonTarget');
  const RequestAQuoteText = checkValue(data?.DisplayOptions, 'RequestAQuoteButtonText', locale);
  // const RequestAQuoteURL = checkValue(data?.DisplayOptions, 'RequestAQuoteButtonUrl', locale);
  const RequestAQuoteURL = `/dealer/contact-a-dealer?product=${data.ProductId}`;

  //Give full Sell Price
  const sellPrice = completeSellPrice(pageData.SellPrice, pageData.LabourRate, pageData.LabourHours, pageData.PDIHours, pageData.AdminFees);

  useEffect(() => {
    switch (productType) {
      case 6:
      case 11:
        setPageType('ProductPage');
        break;
      case 5:
        setPageType('ProductGroup');
        break;
      default:
        setPageType('CategoryGroup');
        break;
    }
    /*
    // set Hero Media Seaonality
    if (['F', 'N', 'P', 'W'].includes(heroSeasonality)) {
      setHeroSeasonalityType(heroSeasonality);
    } else {
      setHeroSeasonalityType('N');
    }
    */
  }, []);

  useEffect(async () => {
    if (studioAvailable) {
      const fetchedStudioResult = await StudioCheckup(pageData.ProductCode, '640x640');
      setStudioFetchedUrl(fetchedStudioResult);
    }
  }, [pageData?.ProductCode]);

  useEffect(async () => {
    if (backgroundHeroEnabled) {
      try {
        const fetchedBgResult = await BackgroundHeroCheckup(
          pageData.ProductCode,
          heroSeasonalityType,
          ComponentTall
            ? isDesktop
              ? `1920x650`
              : isTablet
              ? `1366x650`
              : isMobile
              ? `400x550`
              : null
            : isDesktop
            ? `1920x500`
            : isTablet
            ? `1366x500`
            : isMobile
            ? `640x360`
            : null
        );
        setBackgroundHeroFetchedUrl(fetchedBgResult);
      } catch (error) {
        console.error(`Error loading PostBgHeroCheckup image with ID: ${pageData?.Id}`, error?.message);
      }
    }
  }, [backgroundHeroEnabled, pageData.ProductCode, heroSeasonalityType]);

  const setType = () => {
    if (isTablet || isMobile) {
      if (backgroundHeroEnabled && heroVideoMobileEnabled) {
        return 'both';
      } else if (backgroundHeroEnabled && !heroVideoMobileEnabled) {
        return 'image';
      } else if (!backgroundHeroEnabled && heroVideoMobileEnabled) {
        return 'video';
      } else {
        return null;
      }
    }
    if (backgroundHeroEnabled && heroVideoEnabled) {
      return 'both';
    } else if (backgroundHeroEnabled && !heroVideoEnabled) {
      return 'image';
    } else if (!backgroundHeroEnabled && heroVideoEnabled) {
      return 'video';
    } else {
      return null;
    }
  };

  const getBackground = () => {
    if (isTablet || isMobile) {
      if (backgroundHeroEnabled && heroVideoMobileEnabled) {
        return {
          imageSource: {
            src: backgrondHeroFetchedUrl,
            alt: heroImgAlt,
            type: 'both',
            width: isTablet ? '1366' : isMobile ? '400' : null,
            isHero: true
          },
          videoSource: {
            source: [
              {
                src: heroVideoMobileUrl,
                type: 'both'
              }
            ]
          }
        };
      } else if (backgroundHeroEnabled && !heroVideoMobileEnabled) {
        return {
          source: {
            src: backgrondHeroFetchedUrl,
            alt: heroImgAlt,
            type: 'image',
            width: isTablet ? '1366' : isMobile ? '400' : null,
            isHero: true
          }
        };
      } else if (!backgroundHeroEnabled && heroVideoMobileEnabled) {
        return {
          source: [
            {
              src: heroVideoMobileUrl,
              type: 'video'
            }
          ]
        };
      } else {
        return null;
      }
    } else {
      if (backgroundHeroEnabled && heroVideoEnabled) {
        return {
          imageSource: {
            src: backgrondHeroFetchedUrl,
            alt: `${heroImgAlt}`,
            type: 'both',
            width: isDesktop ? `1920` : isTablet ? `1366` : isMobile ? `400` : null,
            isHero: true
          },
          videoSource: {
            source: [
              {
                src: `${heroVideoUrl}`,
                type: 'both'
              }
            ]
          }
        };
      } else if (backgroundHeroEnabled && !heroVideoEnabled) {
        return {
          source: {
            src: backgrondHeroFetchedUrl,
            alt: `${heroImgAlt}`,
            type: 'image',
            width: isDesktop ? `1920` : isTablet ? `1366` : isMobile ? `400` : null,
            isHero: true
          }
        };
      } else if (!backgroundHeroEnabled && heroVideoEnabled) {
        return {
          source: [
            {
              src: `${heroVideoUrl}`,
              type: 'video'
            }
          ]
        };
      } else {
        return null;
      }
    }
  };

  // check Desktop badge
  const DesktopBadge = checkEnabled(data?.DisplayOptions, 'BadgeDesktop');
  const DesktopBadgePath = data?.DisplayOptions?.find((x) => x.DisplayOptionKey == 'BadgeDesktop')?.OptionInformation;

  // check tablet badge
  const TabletBadge = checkEnabled(data?.DisplayOptions, 'BadgeTablet');
  const TabletBadgePath = data?.DisplayOptions?.find((x) => x.DisplayOptionKey == 'BadgeTablet')?.OptionInformation;

  // check mobile badge
  const MobileBadge = checkEnabled(data?.DisplayOptions, 'BadgeMobile');
  const MobileBadgePath = data?.DisplayOptions?.find((x) => x.DisplayOptionKey == 'BadgeMobile')?.OptionInformation;

  const BadgeObject = {
    DesktopBadge: DesktopBadge,
    DesktopBadgePath: DesktopBadgePath,
    TabletBadge: TabletBadge,
    TabletBadgePath: TabletBadgePath,
    MobileBadge: MobileBadge,
    MobileBadgePath: MobileBadgePath,
    linkActive: DesktopBadge || MobileBadge || rateTextEnable
  };

  return (
    <>
      <div className={styles.Hero}>
        {componentIsAvailable && (
          <Hero
            productCode={pageData.ProductCode}
            badge={BadgeObject}
            componentTall={ComponentTall}
            pageType={pageType}
            DarkMode={DarkMode}
            alt={pageData.ProductText?.Title}
            breadcrumbs={breadCrumbEnabled ? pageData.BreadCrumb : null}
            shadowType={pageType == 'ProductGroup' ? 'DarkerShadow' : pageType == 'CategoryGroup' ? 'DefaultShadow' : null}
            hasShadow={pageType !== 'ProductPage' ? true : false}
            specialBlurbIsAvailable={specialBlurbIsAvailable}
            specialBlurbText={specialBlurbText}
            type={setType()}
            background={getBackground()}
            image={studioFetchedUrl}
            openTermConditionModal={OpenTermConditionModal}
          >
            <Hero.Title>{pageData.ProductText?.Title}</Hero.Title>
            <Hero.SubTitle>{pageData.ProductText?.SubTitle}</Hero.SubTitle>
            <Hero.Text>
              {(isDesktop || isTablet) && LargeDescription && pageData.ProductText?.LargeDescription && (
                <p className={styles.Descriptions}>{pageData.ProductText?.LargeDescription}</p>
              )}
              {(isDesktop || isTablet) && SmallDescription && pageData.ProductText?.SmallDescription && (
                <p className={styles.Descriptions}>{pageData.ProductText?.SmallDescription}</p>
              )}
            </Hero.Text>
            {promotionEnabled && promotion?.length > 0 && (
              <Hero.Label>
                {engDesc} {specialBlurbIsAvailable ? '*' : null}
              </Hero.Label>
            )}
            {sellPriceEnable && sellPrice > 0 && (
              <Hero.SellPrice className={checkTermCondition ? styles.HasInfo : null} onClick={OpenTermConditionModal}>
                {sellPriceText} {translatePrice(sellPrice, locale)}
              </Hero.SellPrice>
            )}
            {(monthlyTextEnable || rateTextEnable || warrantyIsAvailable) && (
              <Hero.Text>
                <ul className={styles.FinanceTerms}>
                  {monthlyTextEnable && (
                    <li className={checkTermCondition ? styles.HasInfo : null} onClick={OpenTermConditionModal}>
                      {monthlyText} <strong>${pageData.MonthlyPayment}</strong>
                    </li>
                  )}
                  {rateTextEnable && (
                    <li className={checkTermCondition ? styles.HasInfo : null} onClick={OpenTermConditionModal}>
                      {rateText}
                      <strong>
                        {' '}
                        {pageData.Rate}% {termText} {pageData.Term} mth
                      </strong>
                    </li>
                  )}
                  {warrantyIsAvailable && (
                    <li onClick={OpenWarrantyModal} className={checkWarranty ? styles.HasWarrantyInfo : null}>
                      {warrantyText} <strong>{pageData.Warranty}</strong>
                    </li>
                  )}
                </ul>
                <Modal
                  show={showTermConditionModal}
                  type="ContentModal"
                  solidBg="true"
                  title={termsConditionsText}
                  onHide={() => setShowTermConditionModal(false)}
                >
                  <div className={styles.ModalContent}>
                    {/* {pageData.WarrantyInfo && (
                      <div
                        className={styles.TermContent}
                        dangerouslySetInnerHTML={{
                          __html: autop(pageData?.WarrantyInfo)
                        }}
                      />
                    )} */}
                    <p>{termsConditionsSpecificText}</p>
                    <p>{termsConditionsMainText}</p>
                  </div>
                </Modal>
                <Modal show={showWarrantyModal} type="ContentModal" solidBg="true" title={warrantyText} onHide={() => setShowWarrantyModal(false)}>
                  {pageData.WarrantyInfo && (
                    <div className={styles.ModalContent}>
                      <div
                        className={styles.WarrantyContent}
                        dangerouslySetInnerHTML={{
                          __html: autop(pageData.WarrantyInfo)
                        }}
                      />
                    </div>
                  )}
                </Modal>
              </Hero.Text>
            )}
            {(BuildYourOwnEnabled || RequestAQuoteEnabled) && (
              <Hero.ButtonsContainer>
                {BuildYourOwnEnabled && (
                  <Button animated href={BuildYourOwnUrl}>
                    {BuildYourOwnText}
                  </Button>
                )}
                {RequestAQuoteEnabled && (
                  <Button className={styles.RequestQuoteBtn} animated light href={RequestAQuoteURL}>
                    {RequestAQuoteText}
                  </Button>
                )}
              </Hero.ButtonsContainer>
            )}
          </Hero>
        )}
      </div>
      {isMobile && (
        <div className={styles.MobileDescription}>
          {LargeDescription && (
            <Hero.Text>
              <p className={styles.Descriptions}>{pageData.ProductText?.LargeDescription}</p>
            </Hero.Text>
          )}
          {SmallDescription && (
            <Hero.Text>
              <p className={styles.Descriptions}>{pageData.ProductText?.SmallDescription}</p>
            </Hero.Text>
          )}
        </div>
      )}
    </>
  );
};

export default ProductHeroSection;
